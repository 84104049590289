import React from 'react'
import filterGif from "../../Assets/props/filterproduct.gif"

const NoResultFound = () => {
    return (
        <div className='bg-[#f3f5f8] min-h-[40vh] flex flex-col justify-center items-center py-8'>
            <img src={filterGif} />
            <h5 className='font-bold'>Your filters produced no results</h5>
            <p className='text-sm'>Try adjusting or clearing your filters to display better results.</p>
        </div>
    )
}

export default NoResultFound