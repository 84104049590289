import React, { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import CloseIcon from '@mui/icons-material/Close';

const Filter = ({ setFilterParams }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    const [filters, setFilters] = useState({
        category: [],
        gemstone: [],
        shape: [],
    });

    // Load filters from localStorage on component mount
    useEffect(() => {
        try {
            const storedFilters = JSON.parse(localStorage.getItem('filters'));
            if (storedFilters) {
                setFilters(storedFilters);
                const query = generateQuery(storedFilters);
                setFilterParams(query);
            }
        } catch (error) {
            console.error("Error parsing filters from localStorage", error);
        }
    }, []);
    window.addEventListener("load", () => {
        resetFilters()
    })

    // Handle checkbox change
    const handleCheckboxChange = (event, type) => {
        const { id, checked } = event.target;

        setFilters((prevFilters) => {
            const updatedType = checked
                ? [...prevFilters[type], id]
                : prevFilters[type].filter((item) => item !== id);
            const updatedFilters = { ...prevFilters, [type]: updatedType };
            // Save the updated filters to localStorage
            localStorage.setItem('filters', JSON.stringify(updatedFilters));
            return updatedFilters;
        });
    };

    // Generate query string from filters
    const generateQuery = (filters) => {
        return `category=${filters.category.join(',')}&gemstone=${filters.gemstone.join(',')}&shape=${filters.shape.join(',')}`;
    };

    // Apply filters and generate the query
    const applyFilters = () => {
        const query = generateQuery(filters);
        setFilterParams(query);
    };

    // Reset filters and clear localStorage
    const resetFilters = () => {
        localStorage.removeItem('filters');
        setFilterParams('');
        setFilters({
            category: [],
            gemstone: [],
            shape: [],
        });
    };

    return (
        <div>
            <div className='pl-[10%]'>
                <span onClick={toggleDrawer} className={`cursor-pointer px-4 py-2 duration-300 rounded-md ${isOpen && "shadow-[1px_2px_5px_#808080_inset]"}`}>Filter <span className='text-xs absolute'>{filters.category.length + filters.gemstone.length + filters.shape.length !== 0 && filters.category.length + filters.gemstone.length + filters.shape.length}</span> &nbsp; <FilterListIcon /></span>
            </div>
            <Drawer
                open={isOpen}
                size={window.innerWidth < 600 ? 350 : 500}
                onClose={toggleDrawer}
                direction='right'
            >
                <div className='pt-4 flex justify-center h-full'>
                    <div className='pt-8 w-[80%] flex flex-col justify-between'>
                        <div className='overflow-auto'>
                            <div className='flex justify-between px-4'>
                                <div className='font-martel text-lg'>Filter</div>
                                <div onClick={toggleDrawer} className='cursor-pointer'><CloseIcon /></div>
                            </div>
                            <div className=" grid divide-y divide-neutral-200 p-4">
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                                            <div className='flex gap-4'>
                                                <span className='text-lg'>Category </span>
                                                <span className='font-normal'>
                                                    {filters.category.length === "0" ? "" : (<span className='text-xs text-white relative before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:z-[-1] before:absolute before:bg-blue-900 before:w-5 before:h-5 before:rounded-full '>{filters.category.length}</span>)}
                                                </span>
                                            </div>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision"
                                                    stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <div className='space-y-3 group-open:animate-fadeIn mt-3 pl-2'>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='ring'
                                                    checked={filters.category.includes('ring')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Ring</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='earring'
                                                    checked={filters.category.includes('earring')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Earring</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='bracelet'
                                                    checked={filters.category.includes('bracelet')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Bracelet</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='bangle'
                                                    checked={filters.category.includes('bangle')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Bangle</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='necklace'
                                                    checked={filters.category.includes('necklace')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Necklace</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id='pendant'
                                                    checked={filters.category.includes('pendant')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'category')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Pendant</span>
                                            </label>
                                        </div>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                                            <div className='flex gap-4'>
                                                <span className='text-lg'>Gemstone </span>
                                                <span className='font-normal'> {filters.gemstone.length === "0" ? "" : (<span className='text-xs text-white relative before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:z-[-1] before:absolute before:bg-blue-900 before:w-5 before:h-5 before:rounded-full '>{filters.gemstone.length}</span>)}</span>
                                            </div>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision"
                                                    stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <div className='space-y-3 group-open:animate-fadeIn mt-3 pl-2'>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="tanzanite"
                                                    checked={filters.gemstone.includes('tanzanite')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Tanzanite</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="tsavorite"
                                                    checked={filters.gemstone.includes('tsavorite')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Tsavorite</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="pinkspinel"
                                                    checked={filters.gemstone.includes('pinkspinel')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Pink Spinel</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="rhodolite"
                                                    checked={filters.gemstone.includes('rhodolite')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Rhodolite</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="grossular"
                                                    checked={filters.gemstone.includes('grossular')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Grossular</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="malayagarnet"
                                                    checked={filters.gemstone.includes('malayagarnet')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Malaya Garnet</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="morganite"
                                                    checked={filters.gemstone.includes('morganite')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Morganite</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="tourmaline"
                                                    checked={filters.gemstone.includes('tourmaline')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Tourmaline</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="aquamarine"
                                                    checked={filters.gemstone.includes('aquamarine')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'gemstone')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span>Aquamarine</span>
                                            </label>
                                        </div>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                                            <div className='flex gap-4'>
                                                <span className='text-lg'>Shape </span>
                                                <span className='font-normal'> {filters.shape.length === "0" ? "" : (<span className='text-xs text-white relative before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:z-[-1] before:absolute before:bg-blue-900 before:w-5 before:h-5 before:rounded-full '>{filters.shape.length}</span>)}</span>
                                            </div>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision"
                                                    stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <div className='space-y-3 group-open:animate-fadeIn mt-3 pl-2'>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="asscher"
                                                    checked={filters.shape.includes('asscher')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>asscher</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="baguette"
                                                    checked={filters.shape.includes('baguette')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>baguette</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="cushion"
                                                    checked={filters.shape.includes('cushion')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>cushion</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="diamond"
                                                    checked={filters.shape.includes('diamond')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>diamond</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="drops"
                                                    checked={filters.shape.includes('drops')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>drop</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="fancy"
                                                    checked={filters.shape.includes('fancy')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>fancy</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="heart"
                                                    checked={filters.shape.includes('heart')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>heart</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="hexagon"
                                                    checked={filters.shape.includes('hexagon')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>hexagon</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="kite"
                                                    checked={filters.shape.includes('kite')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>kite</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="lg-cushion"
                                                    checked={filters.shape.includes('lg-cushion')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>long cushion</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="lg-hexagon"
                                                    checked={filters.shape.includes('lg-hexagon')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>long hexagon</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="marquise"
                                                    checked={filters.shape.includes('marquise')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>marquise</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="mix"
                                                    checked={filters.shape.includes('mix')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>mix</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="octagon"
                                                    checked={filters.shape.includes('octagon')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>octagon</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="oval"
                                                    checked={filters.shape.includes('oval')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>oval</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="pear"
                                                    checked={filters.shape.includes('pear')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>pear</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="round"
                                                    checked={filters.shape.includes('round')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>round</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="round-cabs"
                                                    checked={filters.shape.includes('round-cabs')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>round cabs</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="shield"
                                                    checked={filters.shape.includes('shield')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>shield</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="sq-cushion"
                                                    checked={filters.shape.includes('sq-cushion')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>square cushion</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="sq-octagon"
                                                    checked={filters.shape.includes('sq-octagon')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>square octagon</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="sq-princess"
                                                    checked={filters.shape.includes('sq-princess')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>square princess</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="triangle"
                                                    checked={filters.shape.includes('triangle')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>triangle</span>
                                            </label>
                                            <label className='flex gap-3 items-center'>
                                                <input
                                                    type="checkbox"
                                                    id="trillion"
                                                    checked={filters.shape.includes('trillion')}
                                                    className="peer cursor-pointer hidden after:opacity-100"
                                                    onChange={(e) => handleCheckboxChange(e, 'shape')}
                                                />
                                                <span className="inline-block w-5 h-5 border-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#333] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"></span>
                                                <span className='capitalize'>trillion</span>
                                            </label>
                                        </div>
                                    </details>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center gap-4 p-4'>
                            <button className='border rounded-full px-4 py-2' onClick={applyFilters}>Apply Filter</button>
                            <button className='bg-black text-white rounded-full px-4 py-2' onClick={resetFilters}>Reset Filter</button>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Filter