import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "./style.css"
import axios from 'axios';
import Records from '../Components/Records/Records';
import Pagination from '../Components/Pagination/Pagination';
import PageLoading from '../Components/LoadingSkeleton/pageLoading';
import Filter from '../Components/Filter/Filter';
import NoResultFound from '../Errorpage/NoResultFound';

const ProductDisplay = () => {
    const location = useLocation();
    const parts = location.pathname.split('/');

    // Get the last and second-to-last parts of the URL
    const [divclassname, setDivclassname] = useState()
    const category = parts[parts.length - 2];
    const subCategory = parts[parts.length - 1];
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);

    const [filterParams, setFilterParams] = useState("");


    const [data, setData] = useState(() => {
        // Check for cached data in session storage
        const cachedData = sessionStorage.getItem(`cdata`);
        return cachedData ? JSON.parse(cachedData) : [];
    });

    useEffect(() => {
        // Reset current page to 1 when category or subCategory changes
        setCurrentPage(1);
    }, [category, subCategory, filterParams]);

    // Check session storage for cached data when the component mounts
    useEffect(() => {
        // console.log(sessionStorage);
        fetchData();
    }, [filterParams, category, subCategory, currentPage]); // Refetch data if category or subcategory changes

    // Fetch data from API
    const fetchData = async () => {
        let query1 = `category=${category === "store" ? subCategory : category}`;
        if (subCategory === "collection") {
            query1 = "";
            setDivclassname("finejewelLandingDiv")
        }
        if (subCategory === "ring") {
            query1 = "category=ring";
            setDivclassname("RingLandingDiv")
        }
        if (subCategory === "earring") {
            query1 = "category=earring";
            setDivclassname("EarringLandingDiv")
        }
        if (subCategory === "bracelet") {
            query1 = "category=bracelet";
            setDivclassname("BraceletLandingDiv")
        }
        if (subCategory === "bangle") {
            query1 = "category=bangle";
            setDivclassname("BangleLandingDiv")
        }
        if (category === "gemstone") {
            query1 = `gemstone=${subCategory}`;
            setDivclassname("GemstoneLandingDiv")
        }
        if (subCategory === "gift") {
            query1 = `gift=all`;
            setDivclassname("GiftsPageBannerDiv")
        }
        if (subCategory === "engagementbridal") {
            query1 = `engagementbridal=all`;
            setDivclassname("EngagementBridalPageBannerDiv")
        }
        if (subCategory === "necklace") {
            setDivclassname("NeckalceLandingDiv")
        }
        if (subCategory === "pendant") {
            setDivclassname("PendantLandingDiv")
        }

        let query2 = '';
        switch (category) {
            case "ring":
                query2 = `rsub=${category === "store" ? "" : subCategory}`;
                setDivclassname("RingLandingDiv")
                break;
            case "earring":
                query2 = `esub=${category === "store" ? "" : subCategory}`;
                setDivclassname("EarringLandingDiv")
                break;
            case "bracelet":
                query2 = `brsub=${category === "store" ? "" : subCategory}`;
                setDivclassname("BraceletLandingDiv")
                break;
            case "bangle":
                query2 = `basub=${category === "store" ? "" : subCategory}`;
                setDivclassname("BangleLandingDiv")
                break;
            case "gift":
                query1 = `gift=${category === "store" ? "all" : subCategory}`;
                query2 = ''; // reset query2
                setDivclassname("GiftsPageBannerDiv")
                break;
            case "engagementbridal":
                query1 = `engagementbridal=${category === "store" ? "all" : subCategory}`;
                query2 = ''; // reset query2
                setDivclassname("EngagementBridalPageBannerDiv")
                break;
            default:
                query2 = "";
                break;
        }

        const query = `${query1}&${query2}&${filterParams}`;

        try {
            // alert(query)
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BASE_APIPATH}/api/v1/allitems?limit=35&page=${currentPage}&${query}`);
            setData(response.items);
            setTotalPages(response.totalPages);
            setLoading(false);
            // Store the fetched data in session storage
            sessionStorage.setItem(`cdata`, JSON.stringify(response.items));
        } catch (error) {
            // Log the error response for better debugging
            console.error('Error fetching data:', error.response || error.message);
            setLoading(false);
        }
    };

    // Save current page to session storage when it changes
    useEffect(() => {
        sessionStorage.setItem('currentPage', currentPage);
    }, [currentPage]);

    // Pagination change handler
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo({ top: 0, behavior: "smooth" })
    };

    return (
        <div className='min-h-screen'>
            <div className={`${divclassname} w-full h-[40vh] md:h-[65vh] lg:h-[90vh] relative`}></div>
            <h1 className='font-martel text-gray-500 text-center text-4xl capitalize pt-8 pb-4'>{subCategory.replace(/-/g, " ")}{subCategory !== "collection" && " Collection"}</h1>
            <div className='font-gotham text-gray-500 flex justify-center items-center px-[20%]'>
                <p className='text-gray-500 font-kalam text-base md:text-xl '>
                </p>
            </div>

            {loading ? (
                <PageLoading />
            ) : (
                <div>
                    {subCategory === "collection" && <Filter setFilterParams={setFilterParams} />}

                    {data.length === "0" ?
                        <NoResultFound /> : (
                            <>
                                <Records data={data} />
                                <Pagination page={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </>
                        )
                    }
                </div>
            )}
        </div>
    );
};

export default ProductDisplay;